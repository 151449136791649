
@font-face {
  font-family: 'arial';
  src: url("./assets/fonts/Arial.ttf");
}

@font-face {
  font-family: 'arial bold';
  src: url("./assets/fonts/Arial Bold.ttf");
}


@font-face {
    font-family: 'font1 bold';
    src: url("./assets/fonts/PlutoSansMedium.ttf");
    //src: url("./assets/fonts/MontserratAlternatesSemibold-02aG.ttf");
}

@font-face {
    font-family: 'font1 medium';
    src: url("./assets/fonts/PlutoSansRegular.ttf");
    //src: url("./assets/fonts/MontserratAlternatesSemibold-02aG.ttf");
}

@font-face {
    font-family: 'font1 light';
    src: url("./assets/fonts/PlutoSansLight.ttf");
    //src: url("./assets/fonts/MontserratAlternatesRegular-4XZW.ttf");
}

@font-face {
    font-family: 'urban tour';
    src: url("./assets/fonts/UrbanTourVariable-Ol9d.ttf");
}

:root {
  font-size: 1.05vh;
}

span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.loading-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.load_quarter_spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
}

.load_quarter_spinner_show {
    height: 10vh;
    transform: scale(1);
}

.load_quarter_spinner_hide {
    height: 0vh;
    transform: scale(0);
}

.container-fluid {
  width: 70%;
    min-width: 1200px;
}

body {
  font-family: "font1 light";
  background-color: #f4f9ff;
  font-size: 1.2rem;
    min-width: 1199px;
}

.ant-tooltip-inner {
  font-size: 12px;
}

.tooltip-icon {
  width: 1.2rem;
  height: 1.2rem;
}

.light {
    font-family: "font1 light";
}

.medium {
    font-family: "font1 medium";
}

.bold {
  font-family: "font1 bold";
}

.lightRatio {
    font-family: "font1 medium";
    fill: #7992BC;
    font-size: 1.5rem;
}

body.summer-mode .lightRatio {
    font-family: "font1 medium";
    fill: #0161A6;
}

body.autumn-mode .lightRatio {
    font-family: "font1 medium";
    fill: #8494b5;
}

body.winter-mode .lightRatio {
    font-family: "font1 medium";
    fill: #b6b8ba;
}

.circle-border{
    width: 3rem;
    height: 3rem;
    border-width: 2px;
    border-radius: 50%;
    border-color: red;
    border-style: solid;
}

.header-wrapper .ant-checkbox input {
  width: auto !important;
}

.header-wrapper .top-bar-wrapper {
  background: linear-gradient(90deg, #0065B1 -0.03%, #0065B1 28.55%, #3C87D7 52.5%);
}

.header-wrapper .top-bar-wrapper .top-bar {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-wrapper .top-bar-wrapper .top-bar .left, .header-wrapper .top-bar-wrapper .top-bar .right {
  display: flex;
  justify-content: center;
  align-items: center;
    height: 48px;
}

.header-wrapper .top-bar-wrapper .top-bar .logo {
  width: 100px;
  border-right: 2px solid white;
}

.header-wrapper .top-bar-wrapper .top-bar .logo img {
  width: 100%;
}

    .header-wrapper .top-bar-wrapper .top-bar .migo {
        padding-top: 5px;
        padding-bottom: 5px;
        height: auto;
        border: none;
        color: white;
        margin-left: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "font1 light";
    }

.header-wrapper .top-bar-wrapper .top-bar .upload-button {
  padding-top: 5px;
  padding-bottom: 5px;
  height: auto;
  background-color: #70B1F5;
  border: none;
  color: white;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "font1 bold";
}

.header-wrapper .top-bar-wrapper .top-bar .upload-button i {
  background-color: white;
  border-radius: 30px;
  padding: 2px;
  display: flex;
  color: #70B1F5;
}

.header-wrapper .top-bar-wrapper .top-bar .right {
  font-family: "font1 bold";
  color: white;
  display: flex;
  align-items: center;
}

.header-wrapper .top-bar-wrapper .top-bar .right .avatar {
  background-color: white;
  color: #4AA0F2;
}

.header-wrapper .top-search-bar-wrapper {
  background-color: white;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.2);
}

.header-wrapper .top-search-bar-wrapper .top-search-bar {
  padding: 1rem 0;
}

.header-wrapper .top-search-bar-wrapper .top-search-bar .row {
  justify-content: space-between;
}

.header-wrapper .top-search-bar-wrapper .top-search-bar .column {
  padding-right: 5px;
}

.header-wrapper .top-search-bar-wrapper .top-search-bar .field {
  text-align: left;
  margin: 0 5px;
  flex: 1;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.header-wrapper .top-search-bar-wrapper .top-search-bar .field.company-id > div {
  justify-content: space-between;
}

.header-wrapper .top-search-bar-wrapper .top-search-bar .field.company-id .checkbox-wrapper {
  margin-bottom: 0.8rem;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}

.header-wrapper .top-search-bar-wrapper .top-search-bar .field.company-id .checkbox-wrapper .ant-checkbox-wrapper {
  margin-right: 5px;
}

.header-wrapper .top-search-bar-wrapper .top-search-bar .field.reporting-period {
  flex: 2;
}

.header-wrapper .top-search-bar-wrapper .top-search-bar .field.employees {
  flex: 0.8;
}

.header-wrapper .top-search-bar-wrapper .top-search-bar .label {
  display: block;
  text-align: left;
  line-height: 20px;
  margin-bottom: 0.8rem;
}

.header-wrapper .top-search-bar-wrapper .top-search-bar .select {
  width: 100%;
}

.header-wrapper .top-search-bar-wrapper .top-search-bar .select .ant-select-selector {
  font-size: 1rem;
}
    .header-wrapper .top-search-bar-wrapper .top-search-bar .theme-radio.field {
        flex: 0.7;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-direction: column;
        font-size: 1rem;
    }


    .header-wrapper .top-search-bar-wrapper .top-search-bar .spring-radio .ant-radio .ant-radio-inner {
        background-color: #f4f9ff; /*inside radio*/
    }

    .header-wrapper .top-search-bar-wrapper .top-search-bar .summer-radio .ant-radio .ant-radio-inner {
        background-color: #b2e0ff; /*inside radio*/
    }

    .header-wrapper .top-search-bar-wrapper .top-search-bar .autumn-radio .ant-radio .ant-radio-inner {
        background-color: #48669e; /*inside radio*/
    }

    .header-wrapper .top-search-bar-wrapper .top-search-bar .winter-radio .ant-radio .ant-radio-inner {
        background-color: #0d131c; /*inside radio*/
    }

    .header-wrapper .top-search-bar-wrapper .top-search-bar .dark-mode-switch.field {
        flex: 0.7;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-direction: column;
    }

    .header-wrapper .top-search-bar-wrapper .top-search-bar .theme-select.field {
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
    }

.body-wrapper {
  padding-bottom: 10px;
}

.body-wrapper .tab {
  font-size: 1.2rem;
}

.graph-tab {
    font-size: 0.2rem;
}

.body-wrapper .tab .ant-tabs-bar.ant-tabs-bar {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}

.body-wrapper .tab .ant-tabs-bar .ant-tabs-nav-container {
  font-size: 1.3rem;
}

.body-wrapper .tab .tab-extra-operation {
  display: flex;
}

.body-wrapper .tab .tab-extra-operation button {
  height: auto;
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  margin: 0 5px;
  display: flex;
  align-items: center;
}

.body-wrapper .tab .tab-extra-operation button.download-pdf {
  color: #0175F3;
  border-color: #0175F3;
  background-color: transparent;
}

.body-wrapper .tab .tab-extra-operation button.run-credit {
    background: dodgerblue; /*run credit score button*/
    border-color: dodgerblue;
    color: white;
}

.body-wrapper .tab .tab-extra-operation button.run-credit:disabled {
    background-color: #C2CFE0;
    border-color: #C2CFE0;
    color: white;
}

.body-wrapper .ant-tabs-nav-scroll {
  text-align: left;
}

.body-wrapper .ant-tabs-nav-scroll .ant-tabs-tab {
  padding: 1rem 16px;
}

.body-wrapper .ant-tabs-nav-scroll .ant-tabs-tab.ant-tabs-tab-active {
  font-weight: bold;
}

.vertical-card-stack .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #7992BC !important; /*Selected tab name*/
    font-weight: bold;
}

.vertical-card-stack .ant-tabs-nav .ant-tabs-tab-btn:hover {
    color: lightgreen; /*hover tab name*/
}

.body-wrapper .shadow-box {
  background: white;
  border: 1px solid #D7D7D7;
  box-sizing: border-box;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.04);
}

.body-wrapper .macro-economic-data {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.body-wrapper .macro-economic-data .header {
  background-color: #F9FAFB;
  text-align: left;
  padding: 5px 10px;
  border-bottom: 1px solid #D7D7D7;
}

.body-wrapper .macro-economic-data .header span {
  font-family: "font1 bold";
  color: #6C7A89;
}

.body-wrapper .macro-economic-data .content {
  padding: 5px 10px 5px 10px;
  flex: 1;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.body-wrapper .macro-economic-data .content .column .field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 5px;
}

.body-wrapper .macro-economic-data .content .column span {
  text-align: left;
}

.body-wrapper .title-wrapper {
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.7rem;
  color: #192A3E;
}

.body-wrapper .title-wrapper .alert {
  flex: 1;
  padding: 4px 15px 4px 37px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  border-left: 3px solid #F44336;
}

.body-wrapper .title-wrapper .alert i, .body-wrapper .title-wrapper .alert .ant-alert-close-icon {
  top: auto;
}

.body-wrapper .title-wrapper .alert .alert-message {
  font-size: 1.1rem;
  color: #A51B07;
}

.body-wrapper .setting-wrapper {
  flex: .3;
}

.body-wrapper .setting-wrapper .row {
  justify-content: space-between;
  height: 100%;
}

.body-wrapper .setting-wrapper span {
  color: #8798AD;
  font-size: 1.2rem;
}

.body-wrapper .setting-wrapper span.ant-radio + * {
  padding-right: 5px;
  padding-left: 5px;
}

.body-wrapper .setting-wrapper .ant-radio-wrapper {
  margin-right: 3px;
}

.body-wrapper .setting-wrapper .duration {
  display: flex;
  padding: 2.5px 5px;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  align-items: center;
  height: 100%;
}

.body-wrapper .setting-wrapper .duration .ant-radio-group {
  flex: 1;
  justify-content: space-around;
}

.body-wrapper .setting-wrapper .data {
  flex-wrap: wrap;
  overflow: hidden;
  height: 100%;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  display: flex;
  padding: 5px 10px;
}

.body-wrapper .setting-wrapper .data .ant-radio-group, .body-wrapper .setting-wrapper .data .ant-checkbox-wrapper {
  display: flex;
  align-items: center;
}

.body-wrapper .setting-wrapper .data button {
  padding: 0 18px;
  display: flex;
  align-items: center;
  height: auto;
  margin-left: auto;
  background: #3BCD94;
  border-color: #3BCD94;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.04);
}

.body-wrapper .setting-wrapper .data button span {
  color: white;
}

.body-wrapper .data-entry-wrapper {
  display: flex;
  flex-direction: column;
}

.body-wrapper .data-entry-wrapper:nth-child(1) {
  flex: 1;
}

.body-wrapper .data-entry-wrapper:nth-child(1) .col-6 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.body-wrapper .data-entry-wrapper:nth-child(1) .col-6 .key-ratio-analysis {
  background-color: white;
}

.body-wrapper .data-entry-wrapper:nth-child(1) .col-6 .key-ratio-analysis .input-wrapper {
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.body-wrapper .data-entry-wrapper .column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.body-wrapper .data-entry-wrapper .rating-score-skew-probabilities .input-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.body-wrapper .data-entry-wrapper .rating-score-skew-probabilities .input-wrapper .diverging-chart {
  flex: 1;
}

    .body-wrapper .data-entry-wrapper .rating-score-skew-probabilities .input-wrapper .diverging-chart-multiple{
        flex: 1;
    }

/*.body-wrapper .data-entry-wrapper .row.no-gutters {*/
/*  height: 100%;*/
/*}*/

.body-wrapper .data-entry-wrapper .same-height {
  height: 100%;
}

.body-wrapper .data-entry-wrapper .same-height.credit-score-classification {
  background: #FFFFFF;
}

.body-wrapper .data-entry-wrapper .result {
  background: #96CCFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
}

.body-wrapper .data-entry-wrapper .data-title {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  background: linear-gradient(0deg, #6AABFF, #6AABFF), #E8F2FF;
  border: 1px solid #5299FF;
  box-sizing: border-box;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.1);
  color: white;
  padding: 5px 10px;
  margin-bottom: 5px;
  border-radius: 3px;
}

.help-readme .data-entry-wrapper .data-title {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    background: white;
    border: 1px solid #5299FF;
    box-sizing: border-box;
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.2);
    color: white;
    padding: 5px 10px;
    margin-bottom: 5px;
    border-radius: 3px;
}


.body-wrapper .data-entry-wrapper .data-title .main-title {
  font-size: 1.6rem;
}

.body-wrapper .data-entry-wrapper .data-title .sub-title {
  font-size: 1.2rem;
}

    .body-wrapper .data-entry-wrapper .input-style-class {
        border-radius: 3px;
        border: 1px solid #EBEBEB;
        overflow: hidden;
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.07);
    }

.body-wrapper .data-entry-wrapper .input-style-class .input-wrapper {
  text-align: left;
  background: linear-gradient(0deg, #FEFEFE, #FEFEFE), #FFFFFF;
  box-sizing: border-box;
  padding: 5px 10px;
}

.body-wrapper .data-entry-wrapper .input-style-class .title {
  color: #7992BC;
  font-family: "font1 bold";
  display: flex;
  align-items: center;
}

.body-wrapper .data-entry-wrapper .input-style-class .label {
  font-family: "font1 light";
}

.body-wrapper .data-entry-wrapper .input-style-class .ant-divider-dashed {
  margin: 5px 0;
}

.body-wrapper .data-entry-wrapper .input-style-class .field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1px 0;
  font-size: 1.1rem;
}

.body-wrapper .data-entry-wrapper .balance-sheet .column-child {
  margin: 0px 10px;
}

.body-wrapper .data-entry-wrapper .balance-sheet .input-wrapper {
  padding: 0px;
}

.body-wrapper .data-entry-wrapper .balance-sheet .title {
  padding: 5px 10px;
  padding-bottom: 0;
}

.body-wrapper .data-entry-wrapper .balance-sheet .result {
  margin-top: 10px;
}

.body-wrapper .data-entry-wrapper .credit-score-classification .score-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 10px 0;
  align-items: center;
  position: relative;
}

.body-wrapper .data-entry-wrapper .credit-score-classification .score-wrapper .label-wrapper {
  position: absolute;
  bottom: 0;
  z-index: 1;
}

.body-wrapper .data-entry-wrapper .credit-score-classification .score-wrapper .score {
  font-size: 4.2rem;
  line-height: 1;
  color: #636E72;
  font-family: "font1 bold";
}

.body-wrapper .data-entry-wrapper .credit-score-classification .score-wrapper .label {
  color: #B2BEC3;
}

.body-wrapper .data-entry-wrapper .credit-score-classification .rating-table {
  display: flex;
  flex-direction: column;
  font-size: 1.1rem;
}

.body-wrapper .data-entry-wrapper .credit-score-classification .rating-table .table-row {
  margin-bottom: 5px;
  display: flex;
}

.body-wrapper .data-entry-wrapper .credit-score-classification .rating-table .table-row .long-col {
  flex: 4;
}

.body-wrapper .data-entry-wrapper .credit-score-classification .rating-table .table-row .short-col {
  flex: 3;
  text-align: center;
}

.body-wrapper .data-entry-wrapper .credit-score-classification .rating-table .table-wrapper:nth-child(4) .ant-divider {
  display: none;
}

.body-wrapper .data-entry-wrapper .rating-score-distribution {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.body-wrapper .data-entry-wrapper .rating-score-distribution .input-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.body-wrapper .data-entry-wrapper .rating-score-distribution .title {
  margin-bottom: 3px;
}

.row.no-gutters.parent-row > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.field input, .table-row input, .result input {
  font-size: 1.1rem;
  padding-right: 3px;
  width: 80px;
  background: #FFFFFF;
  border: 1px solid #E1E1E1;
  box-sizing: border-box;
  box-shadow: inset 0px 3px 7px rgba(0, 0, 0, 0.05);
  border-radius: 0px !important;
  height: 1.7rem !important;
  line-height: 1.7rem !important;
}

.ant-select {
  font-size: 1.2rem;
}

.header-wrapper .top-search-bar-wrapper .top-search-bar .select .ant-select-selector {
  height: 2rem;
}

.header-wrapper .top-search-bar-wrapper .top-search-bar .select .ant-select-selector {
  line-height: 2rem;
}

.header-wrapper .top-search-bar-wrapper .top-search-bar .select .ant-select-selector .ant-select-selection-item {
    line-height: 2rem;
}

.ant-tabs-content input {
  text-align: right;
}

.radarSimple {
    height: 100%;
    flex: 1;
}

.help-readme > .row.no-gutters .col-12 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}


.vs-industry-mode > .row.no-gutters .col-12 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.vs-industry-mode > .row.no-gutters .col-6 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.vs-industry-mode > .row.no-gutters .col-12 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.vs-industry-mode .data-title {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  background: linear-gradient(0deg, #6AABFF, #6AABFF), #E8F2FF;
  border: 1px solid #5299FF;
  box-sizing: border-box;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.2);
  color: white;
  padding: 5px 10px;
  margin-bottom: 5px;
  border-radius: 3px;
}

.vs-industry-mode .data-title .main-title {
  font-size: 1.6rem;
}

.vs-industry-mode .data-title .sub-title {
  font-size: 1.2rem;
}

.vs-industry-mode .input-wrapper {
  text-align: left;
  background: linear-gradient(0deg, #FEFEFE, #FEFEFE), #FFFFFF;
  box-sizing: border-box;
  padding: 5px 10px;
}

.vs-industry-mode .title {
  color: #7992BC;
  font-family: "font1 bold";
  display: flex;
  align-items: center;
}

.vs-industry-mode .input-style-class {
    border-radius: 3px;
    border: 1px solid #EBEBEB;
    overflow: hidden;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.07);
}

.vs-industry-mode .input-style-class .label {
  font-family: "font1 bold";
}

.vs-industry-mode .input-style-class .ant-divider-dashed {
  margin: 5px 0;
}

.vs-industry-mode .input-style-class .field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1px 0;
  font-size: 1.1rem;
}

.vs-industry-mode .balance-sheet .column-child {
  margin: 0px 10px;
}

.vs-industry-mode .balance-sheet .input-wrapper {
  padding: 0px;
}

.vs-industry-mode .balance-sheet .title {
  padding: 5px 10px;
  padding-bottom: 0;
}

.vs-industry-mode .balance-sheet .result {
  margin-top: 10px;
}

.vs-industry-mode .result {
  background: #96CCFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
}

.vs-industry-mode .rating-score-skew-probabilities {
  height: 100%;
  background: white;
}

    .vs-industry-mode .rating-score-skew-probabilities .diverging-chart-multiple {
        height: 100%;
        height: 30vh;
        overflow-y: scroll;
        flex: 1;
    }

    .vs-industry-mode .rating-score-skew-probabilities .radar {
        height: 100%;
        flex: 1;
    }

    .vs-industry-mode .rating-score-skew-probabilities .scatterplot-chart {
        height:100%;
        flex: 1;
    }

.vs-industry-mode .credit-score-classification .score-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 10px 0;
  align-items: center;
  position: relative;
}

.vs-industry-mode .credit-score-classification .score-wrapper .label-wrapper {
  position: absolute;
  bottom: 0;
  z-index: 1;
}

.vs-industry-mode .credit-score-classification .score-wrapper .score {
  font-size: 4.2rem;
  line-height: 1;
  color: #636E72;
  font-family: "font1 bold";
}

.vs-industry-mode .credit-score-classification .score-wrapper .label {
  color: #B2BEC3;
}

.vs-industry-mode .scatter-plot-label {
  margin-left: 3rem;
}

.vs-industry-mode .scatter-plot-label div {
  text-align: center;
}

.vs-industry-mode .scatter-plot-label span {
  font-size: 0.9rem;
  font-weight: bold;
}

.ant-modal-content .ant-modal-title {
  color: #0175F3;
  font-weight: bold;
}


/*--------------------------------NEXT IS DARK MODE--------------------------------------*/

body.dark-mode {
    background-color: #151E2E; /*background screen color*/
}

    body.dark-mode .ant-radio .ant-radio-inner {
        background-color: #929292; /*inside radio*/
    }

    body.dark-mode .ant-checkbox .ant-checkbox-inner {
        background-color: #929292; /*inside checkbox*/
    }

    body.dark-mode .ant-checkbox-checked .ant-checkbox-inner {
        background-color: dodgerblue; /*colour when checked*/
    }

    body.dark-mode .top-search-bar-wrapper {
        background: #2F3439; /*background colour of top search bar*/
        box-shadow: 0px 5px 10px #2B5278; /*shadow between top seach bar and main page*/
    }

        body.dark-mode .top-search-bar-wrapper .label {
            color: #A3ABBD; /*edit box label colour of top search bar*/
        }

        body.dark-mode .top-search-bar-wrapper .field-value {
            color: white; /*edit box entry colour of top search bar*/
        }

        body.dark-mode .top-search-bar-wrapper .select .ant-select-selector {
            background-color: #2F3439; /*inside edit box colour of top search bar*/
            border-color: #6C7A89;
            color: white; /*inside edit box writing colour for top search bar*/
        }

        body.dark-mode .top-search-bar-wrapper .select .ant-select-arrow {
            color: #A3ABBD; /*down arrow in edit box for top search bar*/
        }

    body.dark-mode .body-wrapper .score-wrapper > div > div {
        background: #3D3D3D; /*colour of semi-circle under the arc in the credit score classification*/
    }

    body.dark-mode .body-wrapper .score-wrapper > div:before {
        border-color: #595B5B; /*colour of the arc which isnt rainbow*/
    }

    body.dark-mode .body-wrapper .score-wrapper .label-wrapper span {
        color: white !important; /*colour of the credit score under the arc*/
    }

    body.dark-mode .body-wrapper .ant-tabs-bar {
        border-color: #151E2E; /*line underneath the tab names*/
    }

        body.dark-mode .body-wrapper .ant-tabs-bar .ant-tabs-nav {
            color: #9B9DA0; /*unselected tab name*/
        }

            body.dark-mode .body-wrapper .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab-active {
                color: #588BF7 !important; /*selected tab name*/
            }

            body.dark-mode .body-wrapper .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab:hover {
                color: lightgreen; /*selected tab name*/
            }

        body.dark-mode .body-wrapper .ant-tabs-bar .ant-tabs-ink-bar {
            background-color: #588BF7; /*line under selected tab name*/
        }

    body.dark-mode .body-wrapper .tab .tab-extra-operation button.download-pdf {
        background-color: transparent; /*download button colours*/
        color: white;
        border-color: #fff;
    }    

    body.dark-mode .body-wrapper .tab .tab-extra-operation button.run-credit {
        background-color: #588BF7; /*run credit score button*/
        border-color: #588BF7;
        color: white;
    }

    body.dark-mode .body-wrapper input, body.dark-mode .body-wrapper .duration, body.dark-mode .body-wrapper .data, body.dark-mode .body-wrapper .input-style-class {
        border-color: #686868; /*outline of all sections, all edit boxes, dashed lines*/
    }
    body.dark-mode .body-wrapper .ant-divider-dashed {
        border-color: #3D3D3D; /*dashed lines between sections*/
    }
    body.dark-mode .body-wrapper .ant-divider {
        background: #686868; /*dashed and solid lines between sections*/
    }

    body.dark-mode .body-wrapper .same-height {
        background: #151E2E; /*colour between key ratio and rating skew (should be same as background)*/
    }

    body.dark-mode .body-wrapper .input-style-class {
        background: #3D3D3D !important; /*section under the rating agency table*/
    }

    body.dark-mode .body-wrapper .macro-economic-data {
        border-color: #686868; /*ouline of macro section*/
    }

        body.dark-mode .body-wrapper .macro-economic-data .header {
            background: #4C4C4C; /*background of title in macro section*/
            border-color: #686868; /*line under the macro section title*/
        }

            body.dark-mode .body-wrapper .macro-economic-data .header span {
                color: #9B9DA0; /*macro title text colour*/
            }

        body.dark-mode .body-wrapper .macro-economic-data .content {
            background: #3D3D3D; /*background colour of macro data section*/
        }

            body.dark-mode .body-wrapper .macro-economic-data .content span {
                color: #A3ABBD; /*macro section edit-box name colour*/
            }

            body.dark-mode .body-wrapper .macro-economic-data .content input {
                background: #525252; /*macro edit box colour*/
                box-shadow: inset 0px 2px 4px #3D3D3D;
                color: white; /*macro edit box contecnts colour*/
            }

    body.dark-mode .body-wrapper .title-wrapper {
        color: #9B9DA0; /*Financial Report title*/
    }

        body.dark-mode .body-wrapper .title-wrapper .alert {
            background: #5B5B5B; /*?*/
        }

            body.dark-mode .body-wrapper .title-wrapper .alert .alert-message {
                color: white; /*?*/
            }

    body.dark-mode .body-wrapper .setting-wrapper span {
        color: #A3ABBD; /*radio button and check box labels*/
    }

    body.dark-mode .body-wrapper .setting-wrapper .duration, body.dark-mode .body-wrapper .setting-wrapper .data {
        background: #3D3D3D; /*radio button and check box backbround section*/
    }

        body.dark-mode .body-wrapper .setting-wrapper .duration button, body.dark-mode .body-wrapper .setting-wrapper .data button {
            background: #4DAA90; /*?*/
        }

            body.dark-mode .body-wrapper .setting-wrapper .duration button span, body.dark-mode .body-wrapper .setting-wrapper .data button span {
                color: white; /*?*/
            }

    body.dark-mode .body-wrapper .data-title {
        background: #2B5278; /*Company name title background section*/
        border-color: #686868;
    }

        body.dark-mode .body-wrapper .data-title span {
            color: #93A5CA; /*Company name title text*/
        }

    body.dark-mode .body-wrapper .input-style-class .input-wrapper {
        background: #3D3D3D; /*background colour of each box section*/
    }

    body.dark-mode .body-wrapper .input-style-class .title {
        color: #8494b5; /*title colour for each box-section*/
    }

    body.dark-mode .body-wrapper .input-style-class {
        color: #A3ABBD; /*text colour of each box section*/
    }

/*------------------------------NEXT IS AUTUMN MODE------------------------------------*/

body.autumn-mode, body.autumn-mode .body-wrapper .same-height {
    background-color: #0d131c; /*background screen color*/
}
/* RADIO CHECKBOX SECTION */
    body.autumn-mode .ant-radio .ant-radio-inner, body.autumn-mode .ant-checkbox .ant-checkbox-inner {
        background-color: #48669e; /*inside radio and checkbox*/
    }

body.autumn-mode .ant-checkbox-checked .ant-checkbox-inner {
    background-color: dodgerblue; /*colour when checked*/
}
/* TOP SEARCH BAR SECTION */
    body.autumn-mode .top-search-bar-wrapper {
        background: #1d2940; /*background colour of top search bar*/
        box-shadow: 0px 5px 20px #3C87D7; /*shadow between top seach bar and main page*/
    }

    body.autumn-mode .top-search-bar-wrapper .label {
        color: #A3ABBD; /*edit box label colour of top search bar*/
    }

        body.autumn-mode .top-search-bar-wrapper .field-value {
            color: white; /*edit box entry colour of top search bar*/
        }

        body.autumn-mode .top-search-bar-wrapper .select .ant-select-selector {
            background-color: #293a5b; /*inside edit box colour of top search bar*/
            border-color: #6C7A89;
            color: white; /*inside edit box writing colour for top search bar*/
        }

        body.autumn-mode .top-search-bar-wrapper .select .ant-select-arrow {
            color: #A3ABBD; /*down arrow in edit box for top search bar*/
        }

        body.autumn-mode .top-search-bar-wrapper .font-size-choice{
            color: white;
        }
        /* CREDIT SCORE ARC SECTION */
        body.autumn-mode .body-wrapper .score-wrapper > div:before {
            border-color: #2d3f63; /*colour of the arc which isnt rainbow*/
        }

    body.autumn-mode .body-wrapper .score-wrapper .label-wrapper span {
        color: white !important; /*colour of the credit score under the arc*/
    }
/* TAB BAR SECTION */
    body.autumn-mode .body-wrapper .ant-tabs-nav:before {
        border-bottom-color: #2d3f63; /*line underneath the tab names*/
    }

        body.autumn-mode .body-wrapper .ant-tabs-nav .ant-tabs-tab-btn{
            color: #9B9DA0; /*unselected tab name*/
        }

            body.autumn-mode .body-wrapper .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
                color: dodgerblue; /*selected tab name*/
            }

            body.autumn-mode .body-wrapper .ant-tabs-nav .ant-tabs-tab-btn:hover {
                color: lightgreen; /*selected tab name*/
            }

        body.autumn-mode .body-wrapper .ant-tabs-ink-bar {
            background-color: dodgerblue; /*line under selected tab name*/
        }

/* TABS FOR vs INDUSTRY GRAPHS */
    body.autumn-mode .vertical-card-stack .ant-tabs-nav .ant-tabs-tab {
        border-color: #1d2940;
        background-color: #1d2940;
        border-bottom-color: #2d3f63;        
    }
    body.autumn-mode .vertical-card-stack .ant-tabs-nav .ant-tabs-tab-active {
        border-color: #4a545e;
        border-bottom-color: dodgerblue; /*line under selected tab name*/
    }
    body.autumn-mode .vertical-card-stack .ant-tabs-nav .ant-tabs-tab-btn {
        color: #9B9DA0; /*unselected tab name*/
    }
    body.autumn-mode .vertical-card-stack .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #8494b5 !important; /*Selected tab name*/
        font-weight: bold;
    }
    body .autumn-mode .vertical-card-stack .ant-tabs-nav .ant-tabs-tab-btn:hover {
        color: lightgreen; /*hover tab name*/       
    }

/* BUTTONS SECTION */

    body.autumn-mode .body-wrapper .tab .tab-extra-operation button.download-pdf {
        background-color: transparent; /*download button colours*/
        color: white;
        border-color: #fff;
    }

        body.autumn-mode .body-wrapper .tab .tab-extra-operation button.download-pdf:hover {
            color: lightgreen;
            border-color: lightgreen;
        }        

    body.autumn-mode .body-wrapper .tab .tab-extra-operation button.run-credit {
        background-color: dodgerblue; /*run credit score button*/
        border-color: dodgerblue;
        color: white;
    }

        body.autumn-mode .body-wrapper .tab .tab-extra-operation button.run-credit:hover {
            background-color: lightgreen; /*run credit score button*/
            border-color: lightgreen;
            color: darkgreen;
        }

    body.autumn-mode .body-wrapper .tab .tab-extra-operation .ant-btn-primary[disabled] {
        background-color: transparent; /*run credit score button disabled*/
        border-color: #4a545e;
        color: #4a545e;
    }

    body.autumn-mode .body-wrapper .setting-wrapper .data button {
        background: #4DAA90; /*save botton colour*/
    }

        body.autumn-mode .body-wrapper .setting-wrapper .data button span {
            color: white; /*save button writing*/
        }
/* MACRO ECONOMIC SECTION */
    body.autumn-mode .body-wrapper .macro-economic-data .content {
        background: #1d2940; /*background colour of macro data section*/
    }

    body.autumn-mode .body-wrapper .macro-economic-data .header {
        background: #354b75; /*background of title in macro section*/
        border-color: #6C7A89; /*line under the macro section title*/
    }

        body.autumn-mode .body-wrapper .macro-economic-data .header span {
            color: #A3ABBD; /*macro title text colour*/
        }

    body.autumn-mode .body-wrapper .macro-economic-data .content span {
        color: #A3ABBD; /*macro section edit-box name colour*/
    }
/* BOX SECTION */
    body.autumn-mode .body-wrapper .input-style-class .input-wrapper, body.autumn-mode .body-wrapper .score-wrapper > div > div {
        background: #1d2940; /*background colour of each section*/
    }

    body.autumn-mode .body-wrapper .input-style-class {
        background: #1d2940 !important; /*section under the rating agency table*/
    }

    body.autumn-mode .body-wrapper .ant-divider-dashed {
        border-color: #1d2940; /*dashed lines between sections (same as background color)*/
    }

    body.autumn-mode .body-wrapper .input-style-class {
        color: #A3ABBD; /*text colour of each box section*/
    }
        body.autumn-mode .body-wrapper .input-style-class .title {
            color: #8494b5; /*title colour for each box-section*/
        }

        body.autumn-mode .body-wrapper .input-style-class .result {
            background: #354b75; /*colour of sub-total backgrounds such as Total Assets*/
        }

            body.autumn-mode .body-wrapper .input-style-class .result span {
                color: #A3ABBD; /*colour of sub-total text such as Total Assets*/
            }

        body.autumn-mode .body-wrapper .input-style-class input, body.autumn-mode .body-wrapper .macro-economic-data .content input {
            background: #293a5b; /*edit box colour*/
            box-shadow: inset 0px 2px 4px #1d2940;
            color: white; /*edit box contecnts colour*/
            border-color: #4a545e; /*outline of all edit boxes*/
        }

    body.autumn-mode .body-wrapper .macro-economic-data, body.autumn-mode .body-wrapper input, body.autumn-mode .body-wrapper .duration, body.autumn-mode .body-wrapper .data, body.autumn-mode .body-wrapper .input-style-class {
        border-color: #4a545e; /*outline of all sections*/
    }

    body.autumn-mode .body-wrapper .ant-divider {
        background: #4a545e; /*dashed and solid lines between sections*/
    }
/* RADIO AND CHECKBOX SECTION */
body.autumn-mode .body-wrapper .setting-wrapper span {
    color: #A3ABBD; /*radio button and check box labels*/
}

    body.autumn-mode .body-wrapper .setting-wrapper .duration, body.autumn-mode .body-wrapper .setting-wrapper .data {
        background: #354b75; /*radio button and check box background section*/
    }
/* TITLES */
    body.autumn-mode .body-wrapper .data-title {
        background: #abe8df; /*Company name title background section*/
        border-color: #abe8df;
    }

        body.autumn-mode .body-wrapper .data-title span {
            color: #0d131c !important; /*Company name title text*/
        }

    body.autumn-mode .body-wrapper .title-wrapper {
        color: #8593ac; /*Financial Report title*/
    }

/* ALERT FORMATS (IGNORE) */
        body.autumn-mode .body-wrapper .title-wrapper .alert {
            background: #5B5B5B; /*?*/
        }

            body.autumn-mode .body-wrapper .title-wrapper .alert .alert-message {
                color: white; /*?*/
            }

/*------------------------------NEXT IS SUMMER MODE------------------------------------*/

body.summer-mode, body.summer-mode .body-wrapper .same-height {
    background-color: #D4D9E4; /*background screen color*/
}
/* RADIO CHECKBOX SECTION */
body.summer-mode .ant-radio .ant-radio-inner, body.summer-mode .ant-checkbox .ant-checkbox-inner {
    background-color: #A3B2CE; /*inside radio and checkbox*/
}

body.summer-mode .ant-checkbox-checked .ant-checkbox-inner {
    background-color: dodgerblue; /*colour when checked*/
}

/* TOP SEARCH BAR SECTION */
body.summer-mode .top-search-bar-wrapper {
    background: #e3e7ea; /*background colour of top search bar*/
    box-shadow: 0px 10px 20px #3C87D7; /*shadow between top seach bar and main page*/
}

body.summer-mode .top-search-bar-wrapper .label {
    color: #006cb3; /*edit box label colour of top search bar*/
}

body.summer-mode .top-search-bar-wrapper .field-value {
    color: #0077d0; /*edit box entry colour of top search bar*/
}

body.summer-mode .top-search-bar-wrapper .select .ant-select-selection {
    background-color: white; /*inside edit box colour of top search bar*/
    border-color: #A6AfB8; /*outline of all edit boxes*/
    color: #0077d0; /*inside edit box writing colour for top search bar*/
    box-shadow: inset 1px 2px 7px #D2D7DB;
}

body.summer-mode .top-search-bar-wrapper .select .ant-select-arrow {
    color: hotpink; /*down arrow in edit box for top search bar*/
}

/* CREDIT SCORE ARC SECTION */
body.summer-mode .body-wrapper .score-wrapper > div:before {
    border-color: #c1cad0; /*colour of the arc which isnt rainbow*/
}

body.summer-mode .body-wrapper .score-wrapper .label-wrapper span {
    color: deeppink !important; /*colour of the credit score under the arc*/
}

/* TAB BAR SECTION */
    body.summer-mode .body-wrapper .ant-tabs-nav:before {
        border-color: #9B9DA0; /*line underneath the tab names*/
    }

body.summer-mode .body-wrapper .ant-tabs-nav {
    color: #9B9DA0; /*unselected tab name*/
}

body.summer-mode .body-wrapper .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: dodgerblue !important; /*selected tab name*/
}

body.summer-mode .body-wrapper .ant-tabs-tab-btn:hover {
    color: lightgreen; /*selected tab name*/
}

body.summer-mode .body-wrapper .ant-tabs-ink-bar {
    background-color: dodgerblue; /*line under selected tab name*/
}

/* TABS FOR vs INDUSTRY GRAPHS */
    body.summer-mode .vertical-card-stack .ant-tabs-nav .ant-tabs-tab {
        border-color: #e3e7ea;
        background-color: #e3e7ea;
        border-bottom-color: #9B9DA0;
    }

    body.summer-mode .vertical-card-stack .ant-tabs-nav .ant-tabs-tab-active {
        border-color: #9B9DA0;
        border-bottom-color: dodgerblue; /*line under selected tab name*/
    }

    body.summer-mode .vertical-card-stack .ant-tabs-nav .ant-tabs-tab-btn {
        color: #9B9DA0; /*unselected tab name*/        
    }

    body.summer-mode .vertical-card-stack .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #0161A6 !important; /*Selected tab name*/
        font-weight: bold;
    }

    body.summer-mode .vertical-card-stack .ant-tabs-nav .ant-tabs-tab-btn:hover {
        color: lightgreen; /*hover tab name*/
    }

/* BUTTONS SECTION */
    body.summer-mode .body-wrapper .tab .tab-extra-operation button.download-pdf {
        color: #0175F3;
        border-color: #0175F3;
        background-color: transparent;
    }

body.summer-mode .body-wrapper .tab .tab-extra-operation button.download-pdf:hover {
    color: deeppink;
    border-color: deeppink;
}

    body.summer-mode .body-wrapper .tab .tab-extra-operation button.run-credit {
        background-color: dodgerblue; /*run credit score button*/
        border-color: dodgerblue;
        color: white;
    }

        body.summer-mode .body-wrapper .tab .tab-extra-operation button.run-credit:hover {
            background-color: #3BCD94; /*run credit score button*/
            border-color: #3BCD94;
            color: darkgreen;
        }

body.summer-mode .body-wrapper .setting-wrapper .data button {
    background: #3BCD94; /*save botton colour*/
}

body.summer-mode .body-wrapper .setting-wrapper .data button span {
    color: white; /*save button writing*/
}

    body.summer-mode .body-wrapper .tab .tab-extra-operation .ant-btn-primary[disabled] {
        background-color: transparent; /*run credit score button disabled*/
        border-color: #86898d;
        color: #86898d;
    }

/* MACRO ECONOMIC SECTION */
body.summer-mode .body-wrapper .macro-economic-data .content {
    background: #e3e7ea; /*background colour of macro data section*/
}

body.summer-mode .body-wrapper .macro-economic-data .header {
    background: #007BD4; /*background of title in macro section*/
    border-color: #000db1; /*line under the macro section title*/
}

body.summer-mode .body-wrapper .macro-economic-data .header span {
    color: white; /*macro title text colour*/
}

body.summer-mode .body-wrapper .macro-economic-data .content span {
    color: #006cb3; /*macro section edit-box name colour*/
}
/* BOX SECTION */
body.summer-mode .body-wrapper .input-style-class .input-wrapper, body.summer-mode .body-wrapper .score-wrapper > div > div {
    background: #e3e7ea; /*background colour of each section*/
}

body.summer-mode .body-wrapper .input-style-class {
    background: #e3e7ea !important; /*section under the rating agency table*/
}

body.summer-mode .body-wrapper .ant-divider-dashed {
    border-color: #e3e7ea; /*dashed lines between sections (same as background color)*/
}

body.summer-mode .body-wrapper .input-style-class {
    color: #006cb3; /*text colour of each box section*/
}

body.summer-mode .body-wrapper .input-style-class .title {
    color: #0161A6; /*title colour for each box-section*/
}

body.summer-mode .body-wrapper .input-style-class .result {
    background: #e3e7ea; /*colour of sub-total backgrounds such as Total Assets*/
    box-shadow: inset 1px 2px 7px #BDC1C5;
}

body.summer-mode .body-wrapper .input-style-class .result span {
    color: #006cb3; /*colour of sub-total text such as Total Assets*/
}

body.summer-mode .body-wrapper .input-style-class input, body.summer-mode .body-wrapper .macro-economic-data .content input {
    background: white; /*edit box colour*/
    box-shadow: inset 1px 2px 7px #D2D7DB;
    color: #0077d0; /*edit box contents colour*/
    border-color: #A6AfB8; /*outline of all edit boxes*/
    border-radius: 5px !important;
}

body.summer-mode .body-wrapper .macro-economic-data, body.summer-mode .body-wrapper input, body.summer-mode .body-wrapper .duration, body.summer-mode .body-wrapper .data, body.summer-mode .body-wrapper .input-style-class {
    border-color: #A6AfB8; /*outline of all sections*/
    box-shadow: 3px 3px 5px #c4c6c7;
}

body.summer-mode .body-wrapper .ant-divider {
    background: #c1cad0; /*dashed and solid lines between sections*/
}
/* RADIO AND CHECKBOX SECTION */
body.summer-mode .body-wrapper .setting-wrapper span {
    color: white; /*radio button and check box labels*/
}

body.summer-mode .body-wrapper .setting-wrapper .duration, body.summer-mode .body-wrapper .setting-wrapper .data {
    background: #007BD4; /*radio button and check box background section*/
}
/* TITLES */
body.summer-mode .body-wrapper .data-title {
    background: #e3e7ea; /*Company name title background section*/
    border-color: deeppink;
}

body.summer-mode .body-wrapper .data-title span {
    color: deeppink !important; /*Company name title text*/
}

body.summer-mode .body-wrapper .title-wrapper {
    color: #006cb3; /*Financial Report title*/
}

/* ALERT FORMATS (IGNORE) */
body.summer-mode .body-wrapper .title-wrapper .alert {
    background: #5B5B5B; /*?*/
}

body.summer-mode .body-wrapper .title-wrapper .alert .alert-message {
    color: white; /*?*/
}

/*------------------------------NEXT IS WINTER MODE------------------------------------*/

body.winter-mode, body.winter-mode .body-wrapper .same-height {
    background-color: #0d131c; /*background screen color*/
}
        /* SWITCH RADIO CHECKBOX SECTION */
    body.winter-mode .ant-switch {
        background-color: #3BCD94; /*inside switch*/
    }

    body.winter-mode .ant-checkbox .ant-checkbox-inner {
        background-color: #252a32; /*inside checkbox*/
        border-color: #3BCD94;
    }

        body.winter-mode .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #3BCD94; /*colour when checked*/
        }

    body.winter-mode .ant-radio .ant-radio-inner {
        background-color: #252a32; /*inside radio*/
        border-color: #3BCD94;
    }

        body.winter-mode .ant-radio .ant-radio-inner:after {
            background-color: #3BCD94; /*colour when selected*/
        }
        /* TOP SEARCH BAR SECTION */
    body.winter-mode .top-search-bar-wrapper {
        background: #252a32; /*background colour of top search bar*/
        box-shadow: 0px 10px 20px #0065B1; /*shadow between top seach bar and main page*/
    }

        body.winter-mode .top-search-bar-wrapper .label {
            color: #b6b8ba; /*edit box label colour of top search bar*/
        }

        body.winter-mode .top-search-bar-wrapper .field-value {
            color: #a8bef8; /*edit box entry colour of top search bar*/
        }

        body.winter-mode .top-search-bar-wrapper .select .ant-select-selector {
            background-color: #3d4249; /*inside edit box colour of top search bar*/
            box-shadow: inset 0px 0px 5px #252a32;
            color: white; /*edit box contents colour*/
            border-color: #252a32; /*outline of all edit boxes*/
        }
            body.winter-mode .top-search-bar-wrapper .select .ant-select-selector:hover {
                background-color: #a8bef8; /*inside edit box colour of top search bar*/
                box-shadow: inset 0px 0px 5px #252a32;
                color: black; /*edit box contents colour*/
                border-color: #252a32; /*outline of all edit boxes*/
            }

        body.winter-mode .top-search-bar-wrapper .select .ant-select-arrow {
            color: #a8bef8; /*down arrow in edit box for top search bar*/
        }
        body.winter-mode .top-search-bar-wrapper .select .ant-select-selector:hover .ant-select-arrow {
            color: black; /*down arrow in edit box for top search bar*/
        }

        body.winter-mode .top-search-bar-wrapper .font-size-choice {
            color: white;
        }
        /* CREDIT SCORE ARC SECTION */
    body.winter-mode .body-wrapper .score-wrapper > div:before {
        border-color: #3d4249; /*colour of the arc which isnt rainbow*/
    }

    body.winter-mode .body-wrapper .score-wrapper .label-wrapper span {
        color: #9ea0a4 !important; /*colour of the credit score under the arc*/
    }
        /* TAB BAR SECTION */
    body.winter-mode .body-wrapper .ant-tabs-nav:before {
        border-color: #252a32; /*line underneath the tab names*/
    }

        body.winter-mode .body-wrapper .ant-tabs-nav .ant-tabs-tab-btn{
            color: #86898d; /*unselected tab name*/
        }

            body.winter-mode .body-wrapper .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
                color: #3BCD94 !important; /*selected tab name*/
            }

            body.winter-mode .body-wrapper .ant-tabs-nav .ant-tabs-tab-btn:hover {
                color: lightgreen; /*selected tab name*/
            }

        body.winter-mode .body-wrapper .ant-tabs-ink-bar {
            background-color: #3BCD94; /*line under selected tab name*/
        }

/* TABS FOR vs INDUSTRY GRAPHS */
    body.winter-mode .vertical-card-stack .ant-tabs-nav .ant-tabs-tab {
        border-color: #252a32;
        background-color: #252a32;
        border-bottom-color: #252a32;
    }

    body.winter-mode .vertical-card-stack .ant-tabs-nav .ant-tabs-tab-active {
        border-color: #4a545e;
        border-bottom-color: lightgreen; /*line under selected tab name*/
    }

    body.winter-mode .vertical-card-stack .ant-tabs-nav .ant-tabs-tab-btn {
        color: #86898d; /*unselected tab name*/
    }

    body.winter-mode .vertical-card-stack .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #b6b8ba !important; /*Selected tab name*/
        font-weight: bold;
    }

    body.winter-mode .vertical-card-stack .ant-tabs-nav .ant-tabs-tab-btn:hover {
        color: lightgreen; /*hover tab name*/
    }
        /* BUTTONS SECTION */
    body.winter-mode .body-wrapper .tab .tab-extra-operation button.download-pdf {
        background-color: transparent; /*download button colours*/
        color: white;
        border-color: #fff;
    }

        body.winter-mode .body-wrapper .tab .tab-extra-operation button.download-pdf:hover {
            color: lightgreen;
            border-color: lightgreen;
        }

    body.winter-mode .body-wrapper .tab .tab-extra-operation button.run-credit {
        background-color: transparent; /*run credit score button*/
        border-color: #3BCD94;
        color: #3BCD94;
    }

        body.winter-mode .body-wrapper .tab .tab-extra-operation button.run-credit:hover {
            background-color: lightgreen; /*run credit score button*/
            border-color: lightgreen;
            color: darkgreen;
        }

    body.winter-mode .body-wrapper .setting-wrapper .data button {
        background: #3BCD94; /*save botton colour*/
    }

        body.winter-mode .body-wrapper .setting-wrapper .data button span {
            color: black; /*save button writing*/
        }

    body.winter-mode .body-wrapper .tab .tab-extra-operation .ant-btn-primary[disabled] {
        background-color: transparent; /*run credit score button disabled*/
        border-color: #86898d;
        color: #86898d;
    }
        /* MACRO ECONOMIC SECTION */
    body.winter-mode .body-wrapper .macro-economic-data .content {
        background: #252a32; /*background colour of macro data section*/
    }

    body.winter-mode .body-wrapper .macro-economic-data .header {
        background: #252a32; /*background of title in macro section*/
        border-color: #6d7176; /*line under the macro section title*/
    }

        body.winter-mode .body-wrapper .macro-economic-data .header span {
            color: #b6b8ba; /*macro title text colour*/
        }

    body.winter-mode .body-wrapper .macro-economic-data .content span {
        color: #86898d; /*macro section edit-box name colour*/
    }
        /* BOX SECTION */
    body.winter-mode .body-wrapper .input-style-class .input-wrapper, body.winter-mode .body-wrapper .score-wrapper > div > div {
        background: #252a32; /*background colour of each section*/
    }

    body.winter-mode .body-wrapper .input-style-class {
        background: #252a32 !important; /*section under the rating agency table*/
    }

    body.winter-mode .body-wrapper .ant-divider-dashed {
        border-color: #252a32; /*dashed lines between sections (same as background color)*/
    }

    body.winter-mode .body-wrapper .input-style-class {
        color: #86898d; /*text colour of each box section*/
    }

        body.winter-mode .body-wrapper .input-style-class .title {
            color: #b6b8ba; /*title colour for each box-section*/
        }

        body.winter-mode .body-wrapper .input-style-class .result {
            background: #6d7176; /*colour of sub-total backgrounds such as Total Assets*/
            box-shadow: inset 0px 0px 20px black;
        }

            body.winter-mode .body-wrapper .input-style-class .result span {
                color: #cecfd1; /*colour of sub-total text such as Total Assets*/
            }

        body.winter-mode .body-wrapper .input-style-class input, body.winter-mode .body-wrapper .macro-economic-data .content input {
            background: #3d4249; /*edit box colour*/
            box-shadow: inset 0px 0px 5px #252a32;
            color: white; /*edit box contents colour*/
            border-color: #252a32; /*outline of all edit boxes*/
        }

    body.winter-mode .body-wrapper .macro-economic-data, body.winter-mode .body-wrapper input, body.winter-mode .body-wrapper .duration, body.winter-mode .body-wrapper .data, body.winter-mode .body-wrapper .input-style-class {
        border-color: black; /*outline of all sections*/
        box-shadow: 0px 0px 0px #005698;
    }

    body.winter-mode .body-wrapper .ant-divider {
        background: #3d4249; /*dashed and solid lines between sections*/
    }
        /* RADIO AND CHECKBOX SECTION */
    body.winter-mode .body-wrapper .setting-wrapper span {
        color: #b6b8ba; /*radio button and check box labels*/
    }

    body.winter-mode .body-wrapper .setting-wrapper .duration, body.winter-mode .body-wrapper .setting-wrapper .data {
        background: #252a32; /*radio button and check box background section*/
    }
        /* TITLES */
    body.winter-mode .body-wrapper .data-title {
        background: #252a32; /*Company name title background section*/
        border-color: #a8bef8;
        box-shadow: inset 0px 0px 0px black;
    }
        body.winter-mode .body-wrapper .data-title:hover {
            background: #a8bef8; /*Company name title background section*/
            border-color: #252a32;
            box-shadow: inset 0px 0px 0px black;
        }

        body.winter-mode .body-wrapper .data-title span {
            color: #a8bef8 !important; /*Company name title text*/
        }

        body.winter-mode .body-wrapper .data-title:hover span {
            color: #252a32 !important; /*Company name title text*/
        }

    body.winter-mode .body-wrapper .title-wrapper {
        color: #a8bef8; /*Financial Report title*/
    }

            /* ALERT FORMATS (IGNORE) */
            body.winter-mode .body-wrapper .title-wrapper .alert {
                background: #5B5B5B; /*?*/
            }

                body.winter-mode .body-wrapper .title-wrapper .alert .alert-message {
                    color: white; /*?*/
                }


    /*------------------------------NEXT IS BBG MODE------------------------------------*/

    body.bloomberg-mode, body.bloomberg-mode .body-wrapper .same-height {
        background-color: #0d131c; /*background screen color*/
    }
        /* SWITCH RADIO CHECKBOX SECTION */
        body.bloomberg-mode .ant-switch {
            background-color: #3BCD94; /*inside switch*/
        }

        body.bloomberg-mode .ant-checkbox .ant-checkbox-inner {
            background-color: #86898d; /*inside checkbox*/
            border-color: black;
        }

        body.bloomberg-mode .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #3BCD94; /*colour when checked*/
        }

        body.bloomberg-mode .ant-radio .ant-radio-inner {
            background-color: #86898d; /*inside radio*/
            border-color: black;
        }

            body.bloomberg-mode .ant-radio .ant-radio-inner:after {
                background-color: #ffa500; /*colour when selected*/
            }
        /* TOP SEARCH BAR SECTION */
        body.bloomberg-mode .top-search-bar-wrapper {
            background: #252a32; /*background colour of top search bar*/
            box-shadow: 0px 0px 20px #ffa500; /*shadow between top seach bar and main page*/
        }

            body.bloomberg-mode .top-search-bar-wrapper .label {
                color: #b6b8ba; /*edit box label colour of top search bar*/
            }

            body.bloomberg-mode .top-search-bar-wrapper .field-value {
                color: #ffa500; /*edit box entry colour of top search bar*/
            }

            body.bloomberg-mode .top-search-bar-wrapper .select .ant-select-selector {
                background-color: #ffa500; /*inside edit box colour of top search bar*/
                border-color: #4c3100;
                color: black; /*inside edit box writing colour for top search bar*/
            }

            body.bloomberg-mode .top-search-bar-wrapper .select .ant-select-arrow {
                color: black; /*down arrow in edit box for top search bar*/
            }
        /* CREDIT SCORE ARC SECTION */
        body.bloomberg-mode .body-wrapper .score-wrapper > div:before {
            border-color: #0d131c; /*colour of the arc which isnt rainbow*/
        }

        body.bloomberg-mode .body-wrapper .score-wrapper .label-wrapper span {
            color: #9ea0a4 !important; /*colour of the credit score under the arc*/
        }
        /* TAB BAR SECTION */
        body.bloomberg-mode .body-wrapper .ant-tabs-bar {
            border-color: #252a32; /*line underneath the tab names*/
        }

            body.bloomberg-mode .body-wrapper .ant-tabs-bar .ant-tabs-nav {
                color: #86898d; /*unselected tab name*/
            }

                body.bloomberg-mode .body-wrapper .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab-active {
                    color: #3BCD94 !important; /*selected tab name*/
                }

                body.bloomberg-mode .body-wrapper .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab:hover {
                    color: lightgreen; /*selected tab name*/
                }

            body.bloomberg-mode .body-wrapper .ant-tabs-bar .ant-tabs-ink-bar {
                background-color: #3BCD94; /*line under selected tab name*/
            }
        /* BUTTONS SECTION */
        body.bloomberg-mode .body-wrapper .tab .tab-extra-operation button.download-pdf {
            background-color: transparent; /*download button colours*/
            color: white;
            border-color: #fff;
        }

            body.bloomberg-mode .body-wrapper .tab .tab-extra-operation button.download-pdf:hover {
                color: lightgreen;
                border-color: lightgreen;
            }

        body.bloomberg-mode .body-wrapper .tab .tab-extra-operation button.run-credit {
            background: #ffa500; /*run credit score button*/
            border-color: #ffa500;
            color: black;
            box-shadow: inset 0px 0px 10px black;
        }

            body.bloomberg-mode .body-wrapper .tab .tab-extra-operation button.run-credit:hover {
                background: lightgreen; /*run credit score button*/
                border-color: lightgreen;
                color: darkgreen;
            }

        body.bloomberg-mode .body-wrapper .setting-wrapper .data button {
            background: #3BCD94; /*save botton colour*/
        }

            body.bloomberg-mode .body-wrapper .setting-wrapper .data button span {
                color: black; /*save button writing*/
            }
        /* MACRO ECONOMIC SECTION */
        body.bloomberg-mode .body-wrapper .macro-economic-data .content {
            background: #252a32; /*background colour of macro data section*/
        }

        body.bloomberg-mode .body-wrapper .macro-economic-data .header {
            background: #252a32; /*background of title in macro section*/
            border-color: #6d7176; /*line under the macro section title*/
        }

            body.bloomberg-mode .body-wrapper .macro-economic-data .header span {
                color: #b6b8ba; /*macro title text colour*/
            }

        body.bloomberg-mode .body-wrapper .macro-economic-data .content span {
            color: #86898d; /*macro section edit-box name colour*/
        }
        /* BOX SECTION */
        body.bloomberg-mode .body-wrapper .input-style-class .input-wrapper, body.bloomberg-mode .body-wrapper .score-wrapper > div > div {
            background: #252a32; /*background colour of each section*/
        }

        body.bloomberg-mode .body-wrapper .input-style-class {
            background: #252a32 !important; /*section under the rating agency table*/
        }

        body.bloomberg-mode .body-wrapper .ant-divider-dashed {
            border-color: #252a32; /*dashed lines between sections (same as background color)*/
        }

        body.bloomberg-mode .body-wrapper .input-style-class {
            color: #86898d; /*text colour of each box section*/
        }

            body.bloomberg-mode .body-wrapper .input-style-class .title {
                color: #b6b8ba; /*title colour for each box-section*/
            }

            body.bloomberg-mode .body-wrapper .input-style-class .result {
                background: #6d7176; /*colour of sub-total backgrounds such as Total Assets*/
                box-shadow: inset 0px 0px 20px black;
            }

                body.bloomberg-mode .body-wrapper .input-style-class .result span {
                    color: #cecfd1; /*colour of sub-total text such as Total Assets*/
                }

            body.bloomberg-mode .body-wrapper .input-style-class input, body.bloomberg-mode .body-wrapper .macro-economic-data .content input {
                background: #3d4249; /*edit box colour*/
                box-shadow: inset 0px 0px 5px #252a32;
                color: #ffa500; /*edit box contecnts colour*/
                border-color: #252a32; /*outline of all edit boxes*/
            }

        body.bloomberg-mode .body-wrapper .macro-economic-data, body.bloomberg-mode .body-wrapper input, body.bloomberg-mode .body-wrapper .duration, body.bloomberg-mode .body-wrapper .data, body.bloomberg-mode .body-wrapper .input-style-class {
            border-color: black; /*outline of all sections*/
            box-shadow: 0px 0px 0px #005698;
        }

        body.bloomberg-mode .body-wrapper .ant-divider {
            background: #3d4249; /*dashed and solid lines between sections*/
        }
        /* RADIO AND CHECKBOX SECTION */
        body.bloomberg-mode .body-wrapper .setting-wrapper span {
            color: #b6b8ba; /*radio button and check box labels*/
        }

        body.bloomberg-mode .body-wrapper .setting-wrapper .duration, body.bloomberg-mode .body-wrapper .setting-wrapper .data {
            background: #252a32; /*radio button and check box background section*/
        }
        /* TITLES */
        body.bloomberg-mode .body-wrapper .data-title {
            background: #ffa500; /*Company name title background section*/
            border-color: #ffa500;
            box-shadow: inset 0px 0px 10px black;
        }

            body.bloomberg-mode .body-wrapper .data-title span {
                color: black !important; /*Company name title text*/
            }

        body.bloomberg-mode .body-wrapper .title-wrapper {
            color: #ffa500; /*Financial Report title*/
        }

            /* ALERT FORMATS (IGNORE) */
            body.bloomberg-mode .body-wrapper .title-wrapper .alert {
                background: #5B5B5B; /*?*/
            }

                body.bloomberg-mode .body-wrapper .title-wrapper .alert .alert-message {
                    color: white; /*?*/
                }
/*------------------------------END OF THEMES SECTION---------------------------*/

.input-box-wrapper {
  display: flex;
  align-items: center;
}

.input-box-wrapper .tooltip-icon {
margin-right: 0.5rem;
}

.ant-notification span {
white-space: initial;
}

.field.error input, .result.error input {
border-color: #F77266 !important;
}

.field.warning input, .result.warning input {
border-color: #FFA666 !important;
}

.field.fixed input, .result.fixed input {
border-color: #4CAF50 !important;
}

.field.custom input, .result.custom input {
border-color: #FFA666 !important;
box-shadow: 0 0 10px #FFA666 !important;
}

.ant-spin-nested-loading{
height: 100%;
}

.ant-spin-nested-loading .ant-spin-container{
height: 100%;
}

/*added later by Chen*/
.font-size-choice {
    padding: 5px;
    border-radius: 3px;
    color: black
}

.font-size-choice:hover {
    color: #fff;
    background-color: #1890ff;
}

.vertical-card-stack {
    margin-bottom: 5px;
    flex-grow: 1;
}

.vertical-card-stack .input-style-class {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
}

.vertical-card-stack .input-style-class .input-wrapper{
    flex: 1;
}

.body-wrapper .data-entry-wrapper .row {
    flex: 1;
}

.region.field {
    max-width: 80px;
}

.clusters.field {
    max-width: 120px;
}

.gics-industry.field {
    max-width: 200px;
}

.company-id.field {
    max-width: 160px;
}

.company-name.field {
    max-width: 200px;
}

.employees.field {
    max-width: 150px;
}

.reporting-period.field {
    max-width: 180px;
}

.report-date.field {
    max-width: 150px;
}

.fiscal-date.field {
    max-width: 150px;
}

.ratios {
    height: 100%;
}

.ratios .input-wrapper{
    height: 100%;
}