.landing-page {
    background-color: #fff;
    min-width: 1920px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-form {
    max-width: 440px;
    width: 100%;
    margin-bottom: 52px;
}

.login-form-input {
    height: 55px;
    border: 2px solid #6AABFF;
    border-radius: 10px;
}

.login-form-button {
    width: 73%;
    height: 54px;
    background: #6AABFF;
    border-radius: 10px;
    font-family: "font1 bold";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    /* identical to box height */

    text-align: center;
    letter-spacing: -0.3px;

    color: #FFFFFF;
}

.login-form-button > span {
    display: block;
}

.login-div-appstore {
    width: auto;
    object-fit: contain;
    height: 56px;
}

.login-div-googleplay {
    width: auto;
    height: 55px;
    object-fit: contain;
}

.login-form-subtext {
    font-family: "font1 medium";
    font-style: normal;
    font-weight: normal;
    font-size: 16px !important;
    line-height: 22px;
    /* identical to box height, or 137% */

    display: flex;
    align-items: center;
    letter-spacing: -0.408px;

    color: #C4C4C4;
}

.login-div-title {
    width: 422px;
    height: 136px;
    margin-bottom: 13px;

    font-family: "font1 medium";
    font-style: normal;
    font-weight: normal;
    font-size: 55px;
    line-height: 68px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;
}

.login-div-subtitle {
    width: 451px;
    height: 80px;
    margin-bottom: 28px;

    font-family: "font1 medium";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 150%;
    /* or 30px */

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    letter-spacing: -0.3px;

    color: #FFFFFF;
}

.login-div-help {
    font-family: "font1 light";
    font-style: italic;
    font-weight: normal;
    font-size: 10px;
    color: #FFFFFF;
}

.login-div-laptopphoneapp {
    width: 100%;
    object-fit: contain;
    max-width: 1148px;
}

.login-div {
    width: 1919px;
    height: 993px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-image: url("../../assets/images/LandingPage/blue-curve-bg.png");
    background-size: 125% 100%;
}

.login-div-about {
    background-image: url("../../assets/images/LandingPage/blue-bg.png");
    min-height: 993px;
    height: auto;
}

.login-div-row1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.login-div-row1 .logo{
    width: 73px;
    height: 98px;
    margin-left: 50px;
    margin-top: 50px;
}

.login-div-row1 .login-menu-buttons {
    margin-top: 50px;
    margin-right: 50px;
}

.login-div-row1 .login-menu-buttons button{
    border: none;
    background-color: transparent;
    color: #e2e1e3;
    outline: 0;
    margin-left: 25px;
    font-family: "font1 medium";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 150%;
}

.login-div-row1 .login-menu-buttons button:hover{
    background-color: transparent;
    color: #ffffff;
    margin-left: 25px;
    font-family: "font1 medium";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 150%;
}

.login-div-row2 {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    flex: 1;
}

.login-div-row2-left {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.login-div-row2-left > div{
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-div-row2-right {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: stretch;
}

.login-div-download-row {
    align-self: stretch;
    display: flex;
    justify-content: space-evenly;
}

.login-div-row3 {
    display: flex;
    flex: 1;
    justify-content: end;
    align-items: flex-end;
}

.login-div-row3 .footnote{
    margin-right: 29px;
    margin-bottom: 18px;
    flex: 1;
    font-family: "font1 medium";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    /* identical to box height */

    text-align: right;
    letter-spacing: -0.3px;

    color: #C4C4C4;
}

.login-div2 {
    padding: 99px 0;
    width: 1920px;
    background: #F4F9FF;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 54px;
    align-items: stretch;
    justify-items: center;

    font-family: "font1 medium";
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    color: #3D5E87;
}

.login-div2 .el--2 {
    align-self: center;
}

.login-div2 .phone {
    width: 193px;
    height: 335px;
}

.login-div2 .laptop {
    width: 619px;
    height: 350px;
}

.login-div2-text1 {
    max-width: 444px;
}

.login-div2-text2 {
    max-width: 490px;
}

.login-div2-text3 {
    max-width: 357px;
}

.login-div3 {
    width: 1920px;
    height: 1139px;
    display: flex;
    flex-direction: column;
    padding-top: 100px;
}

.login-div3-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
}

.login-div3-icon-row {
    display: flex;
}

.login-div3-icon-row img{
    flex: 1;
    width: 113px;
    height: 113px;
}

.login-div3-title-row {
    margin-top: 25px;
    display: flex;
}

.login-div3-subtitle-row {
    margin-top: 25px;
    display: flex;
}

.login-div3-item {
    flex: 1;
    display: flex;
    justify-content: center;
}

.login-div3-title {
    font-family: 'font1 bold';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 59px;
    flex: 1;
    color: #A0B8DC;
}

.login-div3-subtitle {
    font-family: 'font1 medium';
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 42px;
    max-width: 490px;
    text-align: center;
    margin-left: 25px;
    margin-right: 25px;
    color: #3D5E87;
}

.about-us {
    font-family: 'font1 medium';
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    color: #c6c6c6;
    line-height: 42px;
    height: 100%;
    margin: 50px;
    padding: 50px;
    align-self: stretch;
    background-color: #00000050;
}

.about-us-content {
    font-family: 'font1 light';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #c6c6c6;
    line-height: 30px;
    height: 100%;
    margin: 10px;
    padding-top: 30px;
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 30px;
    align-self: stretch;
    background-color: #00000050;
}


.whats-migo {
    font-family: 'font1 medium';
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    color: #c6c6c6;
    line-height: 42px;
    height: 100%;
    margin: 50px;
    padding: 50px;
    align-self: stretch;
    background-color: #00000050;
}

.whats-migo-content {
    font-family: 'font1 light';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #c6c6c6;
    line-height: 30px;
    height: 100%;
    margin: 10px;
    padding-top: 30px;
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 30px;
    align-self: stretch;
    background-color: #00000050;
}

.whats-migo-image {
    line-height: 30px;
    height: 100%;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    align-self: stretch;
}

.faq-content {
    font-family: 'font1 light';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #c6c6c6;
    line-height: 30px;
    height: 100%;
    margin: 10px;
    padding-top: 30px;
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 30px;
    align-self: stretch;
    background-color: #00000050;
}

.faq-fixed-slider {
    height: 32px;
    margin: 16px;
    background: rgba(255,255,255,0.2);
}

.faq-content .ant-divider {
    background-color: #c6c6c6;
}

    @media screen and (max-width: 1920px) and (min-width: 1520px) {
        .landing-page {
            min-width: 800px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .login-div {
            width: 100%;
        }

        .login-div2 {
            width: 100%;
            font-size: 28px;
        }

        .login-div3 {
            width: 100%;
        }

        .login-div3-subtitle {
            font-size: 24px;
            line-height: 35px;
        }


        .login-div2 .phone {
            width: 160px;
            object-fit: contain;
            height: auto;
        }

        .login-div2 .laptop {
            width: 400px;
            object-fit: contain;
            height: auto;
        }
    }

    @media screen and (max-width: 1519px) {
        body {
            min-width: 800px;
        }

        .landing-page {
            min-width: 800px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .login-div-title {
            font-size: 40px;
            width: 307px;
            line-height: 49px;
            height: 99px;
        }

        .login-div-subtitle {
            width: 360px;
            font-size: 16px;
        }

        .login-div {
            width: 100%;
            height: 900px;
        }

        .login-div-about {
            min-height: 900px;
            height: auto;
        }

        .login-div2 {
            width: 100%;
            font-size: 25px;
        }

        .login-div3 {
            width: 100%;
        }

        .login-div-row2-right {
            flex: 2;
        }

        .login-div-appstore {
            height: 45px;
        }

        .login-div-googleplay {
            height: 45px;
        }

        .login-div2 .phone {
            width: 160px;
            object-fit: contain;
            height: auto;
        }

        .login-div2 .laptop {
            width: 400px;
            object-fit: contain;
            height: auto;
        }

        .login-div3-title {
            font-size: 40px;
        }

        .login-div3-subtitle {
            font-size: 20px;
            line-height: 35px;
        }
    }